import React, { ReactNode, useState } from "react"
import { FiPlus, FiMinus } from "react-icons/fi"

interface Section {
  title: string
  content: ReactNode
}

type Props = {
  rows: Section[]
}

const Accordion: React.FC<Props> = ({ rows }) => {
  const [activeRow, setActiveRow] = useState(0)

  return (
    <div className="mt-8 bg-[#f8f8f8] border-solid border-[1px] border-[#f8f8f8]">
      {rows.map((row, index) => (
        <div key={`row-${index}`}>
          <button
            type="button"
            className="flex items-center p-3 w-full bg-[#f0f0f0] border-solid border-b-[1px] border-b-[#f0f0f0] text-sm text-[#666666] font-semibold hover:bg-[#f8f8f8] transition-colors"
            onClick={() => {
              if (activeRow === index) setActiveRow(-1)
              else setActiveRow(index)
            }}
          >
            {activeRow === index ? (
              <FiMinus size={20} className="mr-2" />
            ) : (
              <FiPlus size={20} className="mr-2" />
            )}
            {row.title}
          </button>
          {activeRow === index && (
            <div
              className={
                "flex flex-col gap-4 p-5   border-solid border-b-[1px] border-b-[#f0f0f0]"
              }
            >
              {row.content}
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default Accordion
