import React from "react"

import Layout from "@/components/Layout"
import SEO from "@/components/SEO"
import WistiaPlayer from "@/components/WistiaPlayer"
import Accordion from "@/components/Accordion"

type PageProps = {
  data: any
}

const SouthWingFAQs: React.FC<PageProps> = ({ data }: { data: any }) => {
  return (
    <Layout
      sectionOne={
        <>
          <video
            id="bgvid"
            className="absolute inset-0 w-full h-full object-cover -z-50 sm:hidden"
            playsInline
            autoPlay
            muted
            loop
          >
            <source src="/videos/rbai_consultation_banner_720.mp4" type="video/mp4" />
          </video>
          <video
            id="bgvid"
            className="absolute inset-0 w-full h-full object-cover -z-50  hidden sm:block"
            playsInline
            autoPlay
            muted
            loop
          >
            <source src="/videos/rbai_consultation_banner.mp4" type="video/mp4" />
          </video>
          <div className="container text-white pt-60 pb-4 flex flex-col gap-4 items-center text-center">
            <div>
              <div className="pb-2 md:pb-12">
                <h1 className="responsive-heading uppercase">
                  The South Wing <span className="text-primary"> Development</span> FAQ’s
                </h1>
              </div>

              <div className="pt-8 flex justify-center">
                <img src="/images/down.png" alt="Down arrow" />
              </div>
            </div>
          </div>
        </>
      }
    >
      <SEO title="The South Wing Development FAQ’s" />

      <div className="container py-14">
        <div className="flex flex-col gap-4">
          <h3 className="uppercase">Education</h3>
          <Accordion
            rows={[
              {
                title: "Why is this development needed? Can existing buildings not be updated?",
                content: (
                  <>
                    <p>With teaching and learning styles evolving, and the use of technology advancing, this proposal will provide the necessary school infrastructure, with modern fit for purpose facilities, that support the educational needs, growth and development of 1,100 pupils.</p>
                    <p>An extensive review of the School’s estate highlighted that classrooms in the 1920’s W Block no longer meet the Department of Education space standards and are not fit for purpose.</p>
                    <p>A study by the School's structural engineers showed that due to the way the W block is built, it is not possible to adjust the internal layout to provide larger classrooms without almost completely dismantling and rebuilding the structure.</p>
                    <p>With more than 12,000 individual usages each year, the Swimming Pool which was built in the 1960’s, and the 1950’s Dining Hall, serving over 700 boys daily, are no longer fit for purpose.</p>
                  </>
                ),
              },
              {
                title: "Will construction cause disruption to the education of pupils, in particular during exams?",
                content: (
                  <>
                    <p>A scheme of work has been designed and phased to minimise disruption to school life. The existing Dining Hall will remain in use whilst the new one in Phase 1 is built. The existing classrooms in W block will remain in use until the new ones in Phase 1 are built. </p>
                    <p>All construction access will be from the Durham St. entrance at the back of the School and a secure hoarding will separate the building site from the functioning School. The works will be planned to start during the summer holidays to allow demolition and site hoarding to be erected while the School is empty. </p>
                    <p>The existing Swimming Pool will be out of use once work starts, but alternative arrangements will be made to ensure pupils have access to other swimming pools.</p>
                    <p>As with previous construction projects at the School, no noisy construction will be undertaken during exam periods.  </p>
                  </>
                ),
              },
            ]}
          />

          <h3 className="uppercase mt-8">CONSTRUCTION &amp; DEMOLITION</h3>
          <Accordion
            rows={[
              {
                title: "Will the frontage of the Soane Building be adversely affected by the proposed new South Wing?",
                content: (
                  <>
                    <p>The Soane Building is one of the most iconic buildings in Belfast and the proposed new South Wing has been designed to specifically complement it.  When viewed from College Square East, the proposed new building will have a better scale and style in comparison to the existing Dining Hall.</p>
                  </>
                ),
              },
              {
                title: "Why do you need to demolish the W block, attached to the rear of the listed Soane building?",
                content: (
                  <>
                    <p>The W block is included in the listing of the impressive Soane building as it is attached to it, it is unlikely to be listed on its own merits. </p>
                    <p>As outlined above, classrooms no longer meet the Department of Education’s space standards and are not fit for purpose. A study by the School's structural engineers showed that due to the way the W block is built, it is not possible to adjust the internal layout to provide larger classrooms without almost completely dismantling and rebuilding the structure.</p>
                    <p>The floor levels in W block do not match those in the adjoining Soane building, which creates accessibility issues. The W block is also very thermally inefficient. </p>
                    <p>The W block is not of the same quality as the Soane building and its removal will enhance the Soane building, whilst also enabling a much more attractive landscaped Quad (playground) to be created.</p>
                  </>
                ),
              },
              {
                title: "What alternatives were considered before deciding to demolish?",
                content: (
                  <>
                    <p>
                      Consideration was given to extending rooms within the W block or extending the W block outwards to make it wider, but the structural constraints of the building, the space available and the proximity to the Soane Building make these options unworkable.
                    </p>
                  </>
                ),
              },
              {
                title: "Many schools are switching to greenfield sites – why does RBAI see its city centre location as so important?",
                content: (
                  <>
                    <p>RBAI has been situated in the city centre for over 200 years and is a unique asset for Belfast.  This proposal will enable the School to further develop its role and thrive in the City Centre in the 21st century, while contributing to Belfast City Council’s vision of Belfast as a great place to work and live.  </p>
                    <p>The School has a long history of making facilities available for the use of community groups and organisations.  The Swimming Pool, Common Hall and other facilities have been used by more than 40 external organisations in recent years alone.  </p>
                  </>
                ),
              },
              {
                title: "How will the proposal be a sustainable development proposal?",
                content: (
                  <>
                    <p>The use of renewable energy sources including photovoltaic panels and air source heat pumps will provide the School with a more sustainable approach to energy consumption, whilst the use of internal heat recovery systems will minimise energy wastage, reduce overall carbon emissions and ensure compliance with NZEB (Nearly zero-emission building) requirements.</p>
                    <p>The thermal envelope of the new building will offer a substantial improvement in comparison to the energy inefficient buildings it will replace. High levels of floor, wall and roof insulation, highly efficient triple glazed windows and enhanced air tightness throughout will provide better thermal efficiency, higher levels of control and reduced energy wastage. A unique roofing system is to be installed to the top of the new building with an external layer that actively purifies air by neutralising carbon dioxide when rainwater comes into contact with the roof.</p>
                    <p>Building materials and finishes are to be specified with a high-recycled content throughout, and recyclable where appropriate, with requirements that they be provided by local suppliers and manufacturers where feasible to reduce the carbon footprint of the new building and assist the local economy.</p>
                    <p>The use of a Building and Energy Management System (BEMS), along with low energy LED lighting and sensor taps internally will provide the School with greater control and flexibility to monitor, manage and control the buildings mechanical and electrical installation, with the intention of minimising energy consumption.</p>
                    <p>Externally, high quality hard and soft landscaping will greatly improve the quality of the campus external spaces. The installation of new bat and bird boxes, along with further native species-rich planting, trees and shrubbery will provide suitable foraging habitat for local bat and bird populations.</p>
                  </>
                ),
              },
            ]}
          />

          <h3 className="uppercase mt-8">FUNDING</h3>
          <Accordion
            rows={[
              {
                title: "Will RBAI’s plans deprive other schools of important funding?",
                content: (
                  <>
                    <p>RBAI’s proposed plans will not compete with any schools seeking public funding.  A funding model is in place and voluntary donations will play an important role in the successful delivery of the scheme.</p>
                  </>
                ),
              },
              {
                title: "Will school fees have to increase to pay for this development. If so, by how much?",
                content: (
                  <>
                    <p>
                      School fees increase annually in line with inflation. A funding package for the proposed scheme has been developed by the Board of Governors and it will have no bearing on school fees.
                    </p>
                  </>
                ),
              },
            ]}
          />

          <h3 className="uppercase mt-8">PLANNING &amp; OPERATIONS</h3>
          <Accordion
            rows={[
              {
                title: "When do you expect construction to begin and when do you expect the extension to be complete?",
                content: (
                  <>
                    <p>Subject to planning permission, the construction of Phase 1 could commence in the summer of 2025 and be completed by the autumn of 2026. At that point assessment will be given to the timeframe for delivering Phase 2.</p>
                  </>
                ),
              },
            ]}
          />
        </div>
      </div>
    </Layout>
  )
}

export default SouthWingFAQs
